@import "src/colorVariable.scss";
#settings {
  background-color: white !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);
    .front-settings-content {
      background-color: rgb(240, 240, 240);
      margin: 20px;

      .headline {
        padding: 20px;
        display: block;

        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      }
     .select-settings {
       display: flex;
       flex-wrap: wrap;
       padding: 30px;
       input {
        height: auto;
        margin: 0 5px;
        display: block;   
        border-radius: 4px; 
       }
       input:not([type="radio"]){
           
        padding: 10px;
        width: 80%;
       }
       .setting-input { width: 33%;padding-bottom: 10px;}
     }
      
    }
  }
}
@media only screen and (max-width: 768px) {
  #settings {
    margin-left: 50px;
    .wrap-content {
      width: calc(100%);
      overflow-x: hidden;
      .save-btn {
        bottom: auto;
        top: 23px;
        left: auto;
        right: 40px;
      }
      .front-settings-content {
        background-color: transparent;
        padding-bottom: 20px;
        margin: 0;
      
        .headline {
          background-color: white;
          width: 100%;
        }
  
      }
    }
  }
}
