@import "src/colorVariable.scss";
#admin-cabins {
  background-color: #f0f0f0 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  .loading {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    z-index: 99999999;
    .spinner {
      position: absolute;
      left: 40%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 80px;
    }
  }
  li {
    list-style-type: none;
  }
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);
    .save-btn {
      background-color: green;
      width: auto;
      display: inline-block;
      padding: 6px 23px;
      color: white;
      font-weight: 300;
      font-size: 14px;
      position: fixed;
      right: 64px;
      top: 24px;
      z-index: 9;
      cursor: pointer;
    }
    .front-page-content {
      background-color: white;
      margin: 20px;
      li {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        margin-left: -40px;
        .list {
          cursor: pointer;
          padding: 15px;
          .list-submenu {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 900;
          }
          .list-icon {
            float: right;
            margin-right: 20px;
          }
        }

        .list-content {
          display: none;
          box-shadow: 0px 1px 0px inset rgba($color: black, $alpha: 0.4);
          padding: 20px;
          padding-bottom: 40px;
          position: relative;
          i {
            text-transform: uppercase;
            font-size: 1rem;
          }
          &.house {
            .full-width {
              max-width: 1440px;
              margin: auto;
              width: 100%;
              display: flex;

              justify-content: space-between;
              > div {
                width: 48%;
                display: flex;
                flex-direction: column;
                input:not([type="checkbox"]) {
                  padding: 0 10px;
                  font-size: 14px;
                  height: 40px;
                }
                input[type="checkbox"] {
                  height: unset;
                }
              }
            }

            .airbnb-uri {
              position: relative;
              input[type="checkbox"] {
                max-height: unset;
                min-height: unset;
                margin-top: -0.5rem;
              }
              label {
                
                margin-left: 10px;
              }
            }
          }
          &.settings {
       
            > div {
              display: flex;
              flex-wrap: wrap;

              > div {
                width: 50%;
                margin-bottom: 30px;
                display: flex;
                flex-direction: column;
                input:not(input[type="checkbox"]) {
                  padding: 0 10px;
                  width: 90%;
                }
                input[type="checkbox"] {
                  position: absolute;
                  top: 6px;
                }
                .quill {
                  width: 90%;
                }
              }
              .full-width {
                width: 100%;
                display: flex;
                .airbnb-uri {
                  label {
                    margin-left: 20px;
                  }
                }
                > div {
                  display: flex;
                  flex-direction: column;
                  width: 100% !important;
                }
                > div:not(.quill) {
                  width: 50%;
                }
                .quill {
                  width: 100%;
                }
              }
            }
          }
          .cabin-name {
            input {
              width: 100%;
            }
          }
          .add-offer {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
            text-align: center;
            color: #6197de;
            font-size: 12px;
          }
          table {
            margin-top: 30px;
            width: 100%;
            input {
              height: auto;
              border-radius: 0;
              padding: 10px;
              font-size: 14px;
            }
            .max-offers {
              margin-top: 5px;
              font-style: italic;
              input {
                margin-left: 5px;
                width: 90px;
                padding: 0 10px;
              }
            }
            .not-valid:focus,
            .not-valid {
              outline: none !important;
              border: 1px solid red;
            }

            textarea {
              font-size: 14px;
            }
            td:first-child {
              min-width: 286px;
              display: block;
            }
            td {
              background-color: white;
              padding: 0 3px 0 5px;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
              textarea {
                resize: both;
                border-radius: 0px;
                max-height: 100px;
                width: 380px;
                vertical-align: middle;
                padding: 10px;
              }
              .delete-offers {
                padding: 10px;
                color: red;
                cursor: pointer;
              }
            }
            .offer-image {
              height: 100px;
              display: inline-block;
              img {
                height: 100%;
              }
            }
            .fileUploader {
              width: 130px;
              display: inline-block;
              margin: 0 20px;
              height: 90px;
              .fileContainer {
                height: 90px;
                margin: 0;
                padding: 0;
                box-shadow: none;
                p {
                  display: none;
                }
                button {
                  font-size: 12px;
                }
              }
            }
          }
          .cabin-week-days {
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            width: 100%;
            max-width: 1440px;

            > div {
              margin-right: 20px;
              input {
                height: auto;
              }
            }
          }
          .cabin-name {
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            width: 100%;
            max-width: 1440px;
            justify-content: space-between;

            > div {
              p {
                margin: 0;
                margin-top: 10px;
              }
              .sub-input {
                margin: 0;
              }
              input:not([type="checkbox"]) {
                width: 300px;
                font-size: 14px;
                height: 40px;
                padding-left: 14px;
                padding-right: 14px;
                border: 1px solid #c6c6c5;
                border-radius: 4px;
                border-radius: 4px;
              }
              input[type="checkbox"] {
                height: auto;
              }
            }
          }
          .cabin-checkbox {
            max-width: 1440px;
            margin: auto;
            width: 100%;
            > div {
              p {
                margin: 0;
                margin-top: 10px;
              }
              input[type="checkbox"] {
                height: auto;
              }
            }
          }
          .cabin-text {
            max-width: 1440px;
            margin: auto;
            width: 100%;

            min-width: 600px;
            .editor {
              // justify-content: space-between;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .heading {
                i {
                  text-transform: uppercase;
                  font-size: 1rem;
                }
              }
              .ql-container.ql-snow {
                background-color: white;
              }
              li {
                margin: auto;
              }
              .text-long,
              .text-short,
              .cabin-special-comments {
                margin-top: 20px;
                width: calc(50% - 60px);
                display: inline-block;
                vertical-align: top;
                p {
                  margin-bottom: 0;
                }
                .quill {
                  height: 200px;
                  margin-bottom: 60px;
                }
              }
              .text-long {
                width: 100%;
              }
            }
          }
          .images {
            max-width: 1440px;
            margin: auto;
            width: 100%;
            .main-image {
              margin: 0;
              margin-right: 20px;
              vertical-align: top;
              width: 150px;
              height: 150px;
              display: inline-block;
              position: relative;
              .fa-trash-alt {
                cursor: pointer;
                right: 3px;
                top: 3px;
                background-color: rgba($color: #ffffff, $alpha: 0.4);
                box-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 1);
                display: block;
                color: red;
                position: absolute;
              }
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
            p {
              margin-top: 20px;
              margin-bottom: 0;
              .image-spinner {
                display: inline;
              }
              .hide-spinner {
                display: none;
              }
            }
            .gallery {
              display: inline-block;
              vertical-align: top;

              .images {
                position: relative;
                width: 150px;
                height: 150px;
                display: inline-block;
                position: relative;
                margin: 20px;
                margin-left: 0;
                margin-top: 0;

                .fa-trash-alt {
                  cursor: pointer;
                  right: 3px;
                  top: 3px;
                  background-color: rgba($color: #ffffff, $alpha: 0.4);
                  box-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 1);
                  display: block;
                  color: red;
                  position: absolute;
                }
                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          position: relative;
          .delete-cabin {
            position: absolute;
            right: 20px;
            bottom: 20px;
            color: white;
            cursor: pointer;
            padding: 10px 20px;
            display: inline;
            background-color: red;
          }
          .add-images {
            cursor: pointer;
            .fileUploader {
              width: 150px;
              display: inline-block;
              vertical-align: top;
            }
            .fileContainer {
              box-shadow: none;
              padding: 0;
              margin: 0;
              p {
                margin: 8px;
              }
            }
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
        .active-list {
          display: flex;
          flex-direction: column;
        }
      }
      .headline {
        padding: 20px;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0px;
        background-color: white;
        width: calc(100% - 257px);
        z-index: 4;
        .add {
          color: #6197de;
          cursor: pointer;
          position: absolute;
          right: 130px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          span {
            display: inline-block;
            vertical-align: middle;

            margin-left: 3px;
          }
          svg {
            display: inline-block;
            vertical-align: middle;
          }
        }
        .sub-menu {
          position: absolute;
          margin-top: 21px;
          background-color: #f0f0f0;
          width: 100%;
          left: 0;
          div:first-child {
            margin-left: 10px;
          }
          div {
            display: inline-block;
            padding: 8px 30px;
            cursor: pointer;
            // border-bottom-left-radius: 4px;
            // border-bottom-right-radius: 4px;
            box-shadow: inset 0px -3px 0px 0px #c4cbcc;
            margin: 0px 10px 10px 0;
            background-color: white;
            border-top: 0px;
            color: #b2b2b2;
            font-size: 12px;
          }
          .selected-sub-menu {
            font-weight: bold;
            background-color: #c4cbcc;
            border-color: #6197de;
            box-shadow: inset 0px -3px 0px 0px #6197de;
            color: white;
          }
        }
      }
      ul {
        margin-top: 140px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #admin-cabins {
    margin-left: 50px;
    .wrap-content {
      width: calc(100% - 50px);
      .save-btn {
        bottom: auto;
        top: 15px;
        left: auto;
        right: 15px;
      }
      .front-page-content {
        background-color: transparent;
        margin: 0;
        .headline {
          position: fixed;
          top: 0;
          z-index: 2;
          background-color: white;
          width: 100%;
          .sub-menu {
            div {
              padding: 8px 20px;
            }
          }
          .add {
            right: 65px;
            top: 65px;
          }
        }
        ul {
          margin-top: 140px;
          margin-left: -20px;
          li {
            overflow-x: hidden;
            margin-left: -20px;
            .list {
              width: calc(100vw - 50px);
              .list-submenu {
                font-size: 12px;
              }
            }
            .list-content {
              padding: 0;
              padding-bottom: 70px;

              .add-images {
                .fileUploader,
                .main-image {
                  width: 39vw;
                  height: 39vw;
                  margin: 5px;
                  .fileContainer .chooseFileButton {
                    padding: 0.6vw 1.8vw;
                  }
                }
                .fileContainer .chooseFileButton {
                  padding: 6px 18px;
                }
                .gallery {
                  .images {
                    width: 39vw;
                    height: 39vw;
                    margin: 5px;
                    .fileContainer .chooseFileButton {
                      padding: 0.6vw 1.8vw;
                    }
                  }
                }
              }
              .cabin-text {
                min-width: calc(100% - 70px);
                width: 100%;
                .editor {
                  .jodit_statusbar {
                    ul {
                      margin: 0px;
                    }
                  }
                  .text-short,
                  .text-long,
                  .cabin-special-comments {
                    padding-right: 0;
                    display: block;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
