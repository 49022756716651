@import "src/colorVariable.scss";
#admin {
  background-color: white !important;
  width: 100%;
  height: 100%;
  position: fixed;

  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: auto;
    font-size: 16px;
  }

  button {
    background-color: #4caf50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
  }

  button:hover {
    opacity: 0.8;
  }

  .cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
  }

  .imgcontainer {
    width: calc(100%);
    text-align: center;
    margin: 24px 0 12px 0;
  }

  img.avatar {
    width: 200px;
  }
  .wrap-container {
    width: calc(100%);
  }
  .container {
    margin: auto;
    padding: 20px;
    max-width: 400px;
  }
}
