@import "src/colorVariable.scss";
#bookedPackage {
  background-color: #f0f0f0 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  .paymentLink {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    input {
      background-color: white;
      padding: 0 20px;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }
    > div {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);
    text-align: center;
    .wrapHeaderFunctions {
      display: flex;
      // float: right;
      align-items: center;
      justify-content: flex-end;
      > div {
        margin-left: 20px;
      }
      .save-btn,
      .add {
        cursor: pointer;
      }
      .save-btn {
        background-color: $color-link;
        padding: 10px 25px;
        color: white;
      }
    }
    .disableSpinner {
      display: none;
    }
    .showMore {
      margin: 10px;
      padding: 5px 10px;
      background-color: $color-first;
      display: inline-block;
      color: white;
      font-size: 12px;
      cursor: pointer;
    }
    .headline {
      padding: 20px;
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      position: sticky;
      top: 0px;
      background-color: white;
      width: calc(100%);
      z-index: 4;
      h3 {
        float: left;
      }
    }
    .refund-ammount {
      padding: 25px 50px;
      position: absolute;
      background-color: white;
      top: calc(50% - 100px);
      left: calc(50% - 200px);
      transform: translate(-50% -50%);
      z-index: 9;
      width: auto;
      text-align: left;
      input[type="radio"],
      .text {
        display: inline-block;
        vertical-align: middle;
      }
      input[type="text"] {
        height: auto;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid;
      }
      .amount {
        display: block;
      }
      .refund-btn,
      .close-btn {
        margin-left: 20px;
        float: right;
        cursor: pointer;
        padding-top: 40px;
      }
      .refund-btn {
        color: #3ea4f4;
      }
    }
    .front-page-content {
      background-color: rgb(240, 240, 240);
      margin: 20px;
      margin-top: 0;
      min-width: 1000px;
      .search {
        position: relative;

        .fa-search {
          position: absolute;
          top: 50%;
          right: 14px;
          transform: translateY(-50%);
          color: rgba($color: #000000, $alpha: 0.3);
        }
        input {
          width: auto;
          height: auto;
          padding-left: 1.4em;
          padding-right: 10px;
          border-radius: 4px;
        }
      }
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 11px;
      }

      table td,
      table th {
        border: 1px solid #ddd;
        padding: 8px;
      }
      table td:last-child {
        padding: 0;
      }
      table {
        min-width: 1000px;
        td {
          word-wrap: break-word;
          text-align: center;
          .react-bootstrap-daterangepicker-container {
            cursor: pointer;
          }

          .action-btn {
            display: inline-block;
            text-align: center;
            width: 50%;
            height: 100%;
            vertical-align: top;
            padding: 5px;

            cursor: pointer;
            p {
              font-size: 12px;
              margin: 0;
            }
          }
          .action-btn:nth-child(3) {
            background-color: red;
            color: white;
          }
          .action-btn:nth-child(2) {
            background-color: $color-first;
            color: white;
          }

          .action-btn:nth-child(1) {
            background-color: green;
            color: white;
          }
          .action-btn:hover {
            opacity: 0.8;
          }
          input {
            height: auto;
            width: 100%;
            text-align: center;
          }
        }
      }
      table tr:nth-child(even) {
        background-color: #f7f7f7;
      }

      table {
        th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: rgba(97, 151, 222, 1);
          color: white;
          position: relative;
          cursor: pointer;
          svg {
            position: absolute;
            right: 3px;
            top: 3px;
          }
          .fa-filter {
            opacity: 0.2;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #booked {
    margin-left: 50px;
    .wrap-content {
      width: calc(100% - 50px);
      .save-btn {
        bottom: auto;
        top: 15px;
        left: auto;
        right: 15px;
      }
      .front-page-content {
        margin: 0;
        .headline {
          position: fixed;
          top: 0;
          z-index: 2;
          background-color: white;
          width: calc(100% - 40px);
          .search {
            padding-right: 0;
            input {
              height: 28px;
            }
          }
        }
        table {
          margin-top: 50vw;
        }
      }
    }
  }
}
