@import "src/colorVariable.scss";
.hide-header {
  display: none;
}
header {
  position: absolute;
  top: 0;
  .wrap-header {
    height: 5vw;
    width: 100%;
    top: 0;
    background-color: $color-first;
    position: fixed;
    z-index: 4;

    .logo {
      float: left;
      margin-left: 5.5vw;
      cursor: pointer;
      img {
        margin-top: 1vw;
        height: 3vw;
      }
    }
    .header-menues {
      float: right;
      display: flex;
      .burger-menu {
        display: none;
      }
      a:hover {
        text-decoration: none;
      }
      a:hover p {
        opacity: 0.7;
      }
      .menu {
        cursor: pointer;
        .font-icon {
          font-size: 1.5vw;
          padding-right: 0.8vw;
        }
        font-size: 1.1vw;
        padding-left: 1.05vw;
        padding-right: 1.05vw;
        font-weight: 500;
        text-transform: uppercase;
        vertical-align: middle;
        display: table-cell;
        height: 5vw;
        color: #ffffff;
      }
      .back-menu {
        display: none;
      }
      .show-back-menu {
        display: inline-block;
      }
      .disable-menu {
        display: none;
      }
      .active {
        color: $color-second;
      }
    }
    &.large {
      .logo {
        img {
          height: 5vw;
          margin-top: 0;
        }
      }
    }
    .wrap-head-lang {
      margin-left: 20px;
      p{
        color: white !important; 
      }
      .active {
        color: $color-link !important
      }

    }
  }
  
  .side-menu{
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  header {
    .wrap-header {
      height: 60px;
      .logo {
        img {
          margin-top: 12px;
          height: 35px;
        }
      }
      &.large {
        .logo {
          img {
            height: 60px;
            margin-top: 0;
          }
        }
      }
      .header-menues {
        margin-right: 60px;
        display: flex;
        .menu {
          font-size: 13px;
          padding-left: 10px;
          padding-right: 10px;
          height: 60px;
          .font-icon {
            font-size: 20px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  header {
    .wrap-header {
      height: 14vw;
      .logo {
        img {
          margin-top: 3.3vw;
          height: 8vw;
        }
      }
      &.large {
        .logo {
          img {
            height: 14vw;
            margin-top: 0;
          }
        }
      }
      .header-menues {
        .burger-menu {
          margin-top: 2vw;
          color: white;
          font-size: 7vw;
          display: block;
          margin-right: 2vw;
        }
        span {
          display: none;
        }
        .wrap-head-lang {
          display: none;
        }
      }
    }
    .active {
      display: block;
    }
    .side-menu {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top:14vw;
      padding-top: 5vw;
      background-color: $color-first ;
      z-index: 2;
      .wrap-head-lang {
        margin-left: 20px;
        p{
          color: white !important; 
        }
        .active {
          color: $color-link !important
        }
  
      }
      .disable-menu {
        display: none;
      }

      .menu {
        font-size: 5vw;
        margin:0px; 
        color: white;
        padding: 1vw;
        max-width: 60vw;
       
        .font-icon {
          width: 10vw;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
}
