
@import "src/colorVariable.scss";
#hide-sidebar {
  display: none;
}
#sidebar {
  background-color: white !important;
  position: fixed;
  min-width: 200px;
  display: inline-block;
  height: 100vh;
  border-right: 0.5px solid #ededed;
  .sidebar-content {
    padding-top: 30px;
    a {
      display: block;
    }

    .menu {
      padding: 10px;
      color: #b2b2b2;
      .menuIcon {
        display: inline-block;
        width: 30px;
        text-align: center;
      }
      .menuText {
        font-size: 12px;
        padding-right: 5px;
      }
    }
    .menu-active {
      background-color: rgba($color: $color-first, $alpha: 0.2);
      box-shadow: inset 0px -3px 0px 0px rgba(97, 151, 222, 1);
      color: white;
      .menuText {
        font-weight: bold;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #sidebar {
    min-width: 50px;
    width: 50px;
    .menuText {
      display: none;
    }
  }
}