@import 'src/colorVariable.scss';
#cabinOffers {
  margin: auto;
  margin-top: 120px;
  width: calc(100% - 120px);
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .all-btn {
    // float: right;
    background-color: green;
    margin-bottom: 60px;
  }
  table {
    background-color: white;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 30px;
    background-color: $color-first;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media (max-width: 768px) {
      margin-top: calc(120px);
    }
    .cardOffer {
      display: flex;
      width: 100%;
      background-color: white;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      > div {
        padding: 20px;
        display: flex;
        > div {
          &.image {
            padding: 0;
          }
        }
        &.actions {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .select-pcs {
            position: relative;
            > div:nth-child(1) {
              position: absolute;
              right: 2px;
              top: 2px;
              display: flex;
              gap: 2px;
              flex-direction: column;
              > div {
                cursor: pointer;
                background-color: #dcdcdc;
                padding: 0 10px;
              }
            }
            .select-people {
              height: 54px;
              display: flex;
              align-items: center;
              padding: 20px;
            }
          }
          .all-btn {
            margin: 0;
            width: 100%;
            background-color: #fab84c;
          }
          .selected {
            background-color: green;
          }
        }
      }
    }

    tr {
      th {
        color: white;
      }
      td {
        border: none;
        background-color: white;
        vertical-align: top;
        padding: 18px 0 0 18px;
        max-height: 200px;
        .select-pcs {
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          .select-people {
            border-radius: 4px;
          }
          .amount-people {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            z-index: -1;
            text-align: center;
            font-size: 16px;
          }
          svg {
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            position: absolute;
          }
          .add-people,
          .remove-people {
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            right: 2px;
            height: 22px;

            width: 30px;
            color: white;
            background-color: #dcdcdc;
            svg {
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .add-people {
            border-top-right-radius: 4px;
            top: 26%;
            // box-shadow: 0 -2px 0 inset white;
          }
          .remove-people {
            top: 74%;
            border-bottom-right-radius: 4px;
          }
          .select-people {
            width: 190px;
            height: 50px;
          }
        }
        h2 {
          font-size: 32px;
          font-weight: 900;
        }
        p {
          max-height: 120px;
          font-size: 12px;
          font-weight: 400;
        }
        .all-btn {
          border-radius: 4px !important;
          width: 190px;
          div {
            display: block;
            padding: 12px;
            width: auto;
            height: auto;
            font-size: 16px;
          }
        }
      }
      td:first-of-type {
        padding: 0;
        width: 266px;
        .image {
          height: 200px;
          width: 266px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      td:last-child {
        text-align: right;
        min-width: 400px;
        padding: 18px;
        position: relative;
        .all-btn {
          background-color: #fab84c;
          margin: auto;
          position: absolute;
          bottom: 20px;
          right: 20px;
          display: inline-block;
        }
        .selected {
          background-color: green;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  #cabinOffers {
    width: calc(100% - 60px);

    table {
      tbody {
        tr {
          td:first-of-type {
            width: 20vw;
            .image {
              width: 23vw;
              height: 22vw;
            }
          }
          td {
            .select-pcs {
              .select-people {
                height: 5vw;
                width: 18vw;
              }
            }
            h2 {
              font-size: 2.8vw;
            }
            p {
            }

            .all-btn {
              width: 18vw;
              div {
                padding: 1.2vw;
              }
            }
          }
          td:last-child {
            min-width: 190px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #cabinOffers {
    .next {
      position: fixed;
      z-index: 9;
      width: calc(100% - 60px);
      top: 14vw;
      div {
        height: auto;
        padding: 16px;
        font-size: 16px;
      }
    }
    .btn-shadow {
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(20, 53, 60, 1)),
        to(rgba(20, 53, 60, 0))
      );
      background: linear-gradient(
        180deg,
        rgba(20, 53, 60, 1),
        rgba(20, 53, 60, 0)
      );
      height: 15vh;
      position: fixed;
      width: calc(100% - 60px);
      top: 14vw;
      z-index: 9;
    }

    table {
      tbody {
        tr {
          td:first-of-type {
            width: 100%;
            .image {
              width: 100%;
              height: 30vh;
            }
          }

          td {
            padding: 30px;
            text-align: center !important;
            max-height: none;
            display: block;
            .select-pcs {
              left: 50% !important;
              right: auto;
              top: -4%;
              transform: translate(-50%, -50%);
              .add-people {
                top: 25%;
              }
              .remove-people {
                top: 50%;
              }
              .add-people,
              .remove-people {
                height: 18px;
                right: 1px;
              }
              .amount-people {
                left: 45%;
              }
              .select-people {
                height: 40px;
                width: 136px;
              }
            }
            h2 {
              font-size: 30px;
              width: 80%;
              margin: auto;
            }
            p {
              width: 80%;
              margin: auto;
              display: block;
              font-size: 16px;
            }

            .all-btn {
              display: block !important;
              position: unset !important;
              float: none;
              width: 90%;
              div {
                padding: 16px;
                font-size: 16px;
              }
            }
          }
          td:last-child {
            min-width: 190px;

            p {
              padding-bottom: 30px;
            }
          }
        }
      }
    }
  }
}
