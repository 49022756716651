@import "src/colorVariable.scss";
#bookingdetails {
  .headline {
    margin-top: 10vw;
    margin-left: 5vw;
    margin-right: 5vw;
    padding-bottom: 2vw;
    h1 {
      font-weight: 900;
      color: white;
    }
  }
  .wrap-detail-content {
    margin: auto;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 5vw;
    .details-form {
      .error-message {
        color: red;
        font-size: 0.8vw;
        position: absolute;
      }
      display: inline-block;
      position: relative;
      width: 65%;
      .first-name,
      .last-name,
      .phone,
      .email,
      .repeat-email,
      .terms {
        color: $color-second;
        width: 50%;
        display: inline-block;
        padding-bottom: 3vw;
        p {
          font-size: 1.3vw;
          margin-bottom: 0.5vw;
        }
        padding-right: 1vw;
        padding-left: 1vw;
        input {
          font-size: 1.6vw;
          height: 4.5vw;
          width: 100%;
        }
        input[type="checkbox"],
        a {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          color: $color-second;

          height: auto;
          width: auto;
        }
      }
      .terms {
        padding-bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          align-items: center;
          label {
            margin: 0;
            cursor: pointer;
            color: white;
          }
        }
      }
      .comment {
        padding-right: 1vw;
        padding-left: 1vw;
        color: $color-second;
        p {
          font-size: 1.3vw;
          margin-bottom: 0.5vw;
        }
        textarea {
          font-size: 1.6vw;
          resize: none;
          padding-top: 1.3vw;
          height: 30vw;
          width: 100%;
        }
      }

      .all-btn {

      }
    }
  }
  .booking-details {
    display: inline-block;
    vertical-align: top;
    width: 35%;
    color: white;

    div {
      display: flex;
      flex-direction: column;
      gap: 2px;
      .packageNamePrice {
        display: flex;
        flex-direction: column;
        span {
          display: flex;
          flex-direction: column;
        }
      }
    }
   
    .coupon {
      margin-top: 1.5vw;
      input {
        width: 100%;
      }
    }
    h3 {
      font-size: 1.6vw;
      font-weight: 900;
      color: $color-second;
    }
    p {
      font-size: 1.3vw;
    }
    .detail-headline {
      font-weight: 900;
      margin: 0;
    }
    .originalPrice {
      margin-top: 40px;
    }
    .per-night {
      font-size: 0.7em;
    }
    .price {
      float: right;
      text-align: right;
    }
    .cleaning {
      width: 100%;
      font-size: 12px;
      display: inline-block;
    }
    .note {
      float: left;
      padding-bottom: 1vw;
      p {
        font-size: 13px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  #bookingdetails {
    .headline {
      margin: auto;
      width: 1080px;
      margin-bottom: 45px;
      margin-top: 160px;

      h1 {
        font-size: 60px !important;
      }
    }

    .wrap-detail-content {
      width: 1080px;
      margin: auto;
      margin-bottom: 60px;
      .details-form {
        .first-name,
        .last-name,
        .phone,
        .email,
        .repeat-email {
          padding-bottom: 34px;
          p {
            font-size: 16px;
            margin-bottom: 6px;
          }
          padding-right: 12px;
          padding-left: 12px;
          input {
            padding-right: 12px;
            padding-left: 12px;
            font-size: 16px;
            height: 55px;
            border-radius: 4px;
          }
        }

        .comment {
          padding-right: 12px;
          padding-left: 12px;
          p {
            font-size: 16px;
            margin-bottom: 6px;
          }
          textarea {
            font-size: 16px;
            padding-top: 12px;
            height: 300px;
            padding-right: 12px;
            padding-left: 12px;
            border-radius: 4px;
          }
        }

        .all-btn {
          margin-top: 24px;
          right: 12px;
        }
      }
    }
    .booking-details {
      padding-left: 60px;
      padding-right: 50px;
      .coupon {
        margin-top: 15px;
        input {
          padding-right: 12px;
          padding-left: 12px;
          font-size: 16px;
          height: 55px;
          border-radius: 4px;
        }
      }
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #bookingdetails {
    .headline {
      margin-top: 20vw;
      h1 {
        font-size: 9vw;
        line-height: 10vw;
        letter-spacing: 0.5vw;
      }
      p {
        font-size: 3.5vw;
        line-height: 5.5vw;
      }
    }
    .wrap-detail-content {
      display: flex;
      flex-flow: column;
      margin-bottom: 30vw;
      .details-form {
        order: 2;
        width: 90vw;
        margin-top: 5vw;
        .error-message {
          font-size: 10px;
        }
        .first-name,
        .last-name,
        .phone,
        .email,
        .repeat-email,
        .comment {
          width: 100%;
          p,
          input,
          textarea {
            font-size: 3.5vw;
            border-radius: 1vw;
          }
          input,
          textarea {
            padding-left: 3vw;
            padding-right: 3vw;
          }
          input {
            height: 14vw;
          }
        }
        .all-btn {
          margin-top: 6vw;
          right: 0;
        }
      }
      .booking-details {
        order: 1;
        width: 100%;
        .coupon {
          margin-top: 2.5vw;
          font-size: 3.5vw;
          input,
          textarea {
            font-size: 3.5vw;
            border-radius: 1vw;
            height: 14vw;
          }
          input,
          textarea {
            padding-left: 3vw;
            padding-right: 3vw;
          }
        }
        h3 {
          font-size: 4vw;
        }
        p {
          font-size: 3.5vw;
        }
        p:nth-child(1),
        p:nth-child(3),
        p:nth-child(5) {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
