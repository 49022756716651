@import "src/colorVariable.scss";
#page {
  background-color: #f0f0f0 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  .loading {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    z-index: 99999999;
    .spinner {
      position: absolute;
      left: 40%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 80px;
    }
  }
  .header {
    margin: 0 20px;
    padding: 20px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0px;
    background-color: white;
    width: calc(100% - 257px);
    z-index: 4;
    .save-btn {
      background-color: green;
      width: auto;
      display: inline-block;
      padding: 6px 23px;
      color: white;
      font-weight: 300;
      font-size: 14px;
      // position: fixed;
      right: 64px;
  
      top: 24px;
      z-index: 9;
      cursor: pointer;
    }
  }
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);
    margin-top: 120px;
    padding-bottom: 120px;
    .wrap-front-page-content {
      margin-top: 120px;
    }
    .front-page-content {
      background-color: white;
      margin: 20px;
      .welcome-editor {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        padding: 20px;
      }
      .headline {
        padding: 20px;
        display: block;

        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        i {
          text-transform: uppercase;
          font-size: 1rem;
          opacity: 0.6;
        }
      }
      .images {
        margin: 20px;
        display: inline-block;
        vertical-align: top;
        width: 150px;
        height: 150px;
        position: relative;
        .fa-trash-alt {
          cursor: pointer;
          right: 3px;
          top: 3px;
          background-color: rgba($color: #ffffff, $alpha: 0.4);
          box-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 1);
          display: block;
          color: red;
          position: absolute;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .add-images {
        cursor: pointer;
        .fileContainer {
          box-shadow: none;
          padding: 0;
          margin: 0;
          p {
            margin: 8px;
          }
        }
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #page {
    margin-left: 50px;
    .header {
      width: 100%;
      margin: 0;
      .save-btn {
        bottom: auto;
        top: 15px;
        left: auto;
        right: 15px;
      }
    }
    .wrap-content {
      width: calc(100%);
      overflow-x: hidden;
      .front-page-content {
        background-color: transparent;
        padding-bottom: 20px;
        margin: 0;
        .welcome-editor {
          width: calc(100% - 40px);
        }
        .images {
          width: 39vw;
          height: 39vw;
          margin: 5px;
          .fileContainer .chooseFileButton {
            padding: 0.6vw 1.8vw;
          }
        }
        .headline {
          background-color: white;
          width: 100%;
        }
        table {
          margin-top: 100px;
        }
      }
    }
  }
}
