@import "src/colorVariable.scss";
#admin-packagesOffers {
  background-color: #f0f0f0 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  li {
    list-style-type: none;
  }
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);
    padding: 0 20px;
    .save-btn {
      background-color: green;
      width: auto;
      display: inline-block;
      padding: 6px 23px;
      color: white;
      font-weight: 300;
      font-size: 14px;
      position: fixed;
      right: 64px;
      top: 24px;
      z-index: 9;
      cursor: pointer;
    }
    .front-page-content {
      .wrap-head-lang {
        background-color: white;
        padding: 20px;
        margin-bottom: 60px;
        position: sticky;
        top:0;
        z-index: 99;
        .add {
          color: #6197de;
          cursor: pointer;
          position: absolute;
          right: 130px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          span {
            display: inline-block;
            vertical-align: middle;

            margin-left: 3px;
          }
          svg {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
      li {
        background-color: white;
        cursor: pointer;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        margin-left: -40px;
        .list {
          padding: 15px;

          .list-icon {
            float: right;
          }
        }

        .list-content {
          cursor: auto;
          display: none;
          box-shadow: 0px 1px 0px inset rgba($color: black, $alpha: 0.4);
          padding: 20px;
          padding-bottom: 40px;
          position: relative;
          i {
            text-transform: uppercase;
            font-size: 1rem;
          }
          .packageOffer-name {
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            width: 100%;
            max-width: 1440px;
            .react-bootstrap-daterangepicker-container {
              display: unset !important;
              width: 100%;
            }
            .hide-from-page,
            .packageUrl {
              display: flex;
              justify-content: space-evenly;
              flex: calc(100% - 100px) 1;
              > div {
                flex-direction: column;
                justify-content: center;
                display: flex;
              }
              input {
                height: unset;
              }
              input:not([type="checkbox"]) {
                width: 100%;
              }
            }
            .hide-from-page {
              flex-basis: unset;
              align-items: baseline;
              margin-top: 10px;
              justify-content:flex-end;
              >div {
                margin-right: 10px;
                // flex-direction: unset;
                align-items: center;
                label {
                  margin:0;
                }
                input, label {
                  max-height: unset;
                  min-height: unset;
                }
              }
            }
            .packageUrl {
              flex-direction: column;
            }
            .seletctDatePref {
              display: flex;
              flex-direction: column;
              select {
                width: 100%;
                height: 40px;
              }
            }
            .specific-dates {
              display: flex; 
              flex-direction: column;
              position: relative;
              flex-basis: unset;
              width: calc(66% - 2px);
             
              .addHead{
                width: 100%;
              }
              .dates {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                >div {
                  width: 48%;
                }
                .remove {
                float: right;
                cursor: pointer;
                }
              }
              label {
                margin-bottom: 0;
              }
              .checkInOut {
                display: flex;
                justify-content: space-between;
                > div {
                  width: calc(50% - 10px);
                  input {
                    margin-right: 0;
                  }
                }
              }
             
              > div {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                padding: 20px 0;
              }
              .add {
                
                cursor: pointer;
                justify-content: flex-end;
              
                color: $color-link;
                svg {
                  margin-right: 10px;
                }
              }
              input {
                margin-bottom: 10px;
                margin-right: 10px;
                width: 100%;
              }
            }
            > div {
              // margin-right: 10px;
              margin-right: 10px;
              flex-basis: calc((100% - 30px) / 3);
              p {
                margin: 0;
                margin-top: 10px;
              }
              input:not([type="checkbox"]) {
                width: 100%;
                font-size: 14px;
                height: 40px;
                padding-left: 14px;
                padding-right: 14px;
                border: 1px solid #c6c6c5;
                border-radius: 4px;
                border-radius: 4px;
              }
            }
          }
          .packageOffer-text {
            max-width: 1440px;
            margin: auto;
            width: 100%;

            min-width: 600px;
            .editor {
              li {
                margin: auto;
              }
              .extra-info,
              .text-short {
                margin-top: 20px;
                width: 50%;
                display: inline-block;
                vertical-align: top;
                p:first-child {
                  margin-bottom: 0;
                }
              }
              .text-short {
                padding-right: 20px;
              }
              .text-long {
                > p:first-child {
                  margin-top: 30px;
                  margin-bottom: 0;
                }
              }
            }
          }
          .packageOffer-week-days {
            width: 100%;
            .days {
              display: inline-block;
              width: 14.2%;
              text-align: center;
              p {
                margin: 0;
              }
              input {
                width: auto;
                height: auto;
              }
            }
          }
          .images {
            max-width: 1440px;
            margin: auto;
            width: 100%;
            .main-image {
              margin: 0;
              margin-right: 20px;
              vertical-align: top;
              width: 150px;
              height: 150px;
              display: inline-block;
              position: relative;
              .fa-trash-alt {
                cursor: pointer;
                right: 3px;
                top: 3px;
                background-color: rgba($color: #ffffff, $alpha: 0.4);
                box-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 1);
                display: block;
                color: red;
                position: absolute;
              }
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
            p {
              margin-top: 20px;
              margin-bottom: 0;
              .image-spinner {
                display: inline;
              }
              .hide-spinner {
                display: none;
              }
            }
            .gallery {
              display: inline-block;
              vertical-align: top;

              .images {
                position: relative;
                width: 150px;
                height: 150px;
                display: inline-block;
                position: relative;
                margin: 20px;
                margin-left: 0;
                margin-top: 0;

                .fa-trash-alt {
                  cursor: pointer;
                  right: 3px;
                  top: 3px;
                  background-color: rgba($color: #ffffff, $alpha: 0.4);
                  box-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 1);
                  display: block;
                  color: red;
                  position: absolute;
                }
                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          position: relative;
          .delete-packageOffer {
            position: absolute;
            right: 20px;
            bottom: 20px;
            color: white;
            cursor: pointer;
            padding: 10px 20px;
            display: inline;
            background-color: red;
          }
          .add-images {
            cursor: pointer;
            .fileUploader {
              width: 150px;
              display: inline-block;
              vertical-align: top;
            }
            .fileContainer {
              box-shadow: none;
              padding: 0;
              margin: 0;
              p {
                margin: 8px;
              }
            }
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
        .active-list {
          display: block;
        }
      }
      .headline {
        padding: 20px;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0px;
        background-color: white;
        width: calc(100% - 257px);
        z-index: 4;
        .add {
          color: #6197de;
          cursor: pointer;
          position: absolute;
          right: 130px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          span {
            display: inline-block;
            vertical-align: middle;

            margin-left: 3px;
          }
          svg {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #admin-packagesOffers {
    margin-left: 50px;
    .wrap-content {
      width: calc(100% - 50px);
      .save-btn {
        bottom: auto;
        top: 15px;
        left: auto;
        right: 15px;
      }
      .front-page-content {
        background-color: transparent;
        margin: 0;
        .headline {
          position: fixed;
          top: 0;
          z-index: 2;
          background-color: white;
          width: 100%;
          .add {
            right: 65px;
            top: 65px;
          }
        }
        ul {
          margin-top: 100px;
          margin-left: -20px;
          li {
            overflow-x: hidden;
            margin-left: -20px;
            .list {
              width: calc(100vw - 50px);
            }
            .list-content {
              padding: 0;
              padding-bottom: 70px;
              .packageOffer-name {
                input:not([type="checkbox"]) {
                  width: 100%;
                }
              }
              .add-images {
                .fileUploader,
                .main-image {
                  width: 39vw;
                  height: 39vw;
                  margin: 5px;
                  .fileContainer .chooseFileButton {
                    padding: 0.6vw 1.8vw;
                  }
                }
                .fileContainer .chooseFileButton {
                  padding: 6px 18px;
                }
                .gallery {
                  .images {
                    width: 39vw;
                    height: 39vw;
                    margin: 5px;
                    .fileContainer .chooseFileButton {
                      padding: 0.6vw 1.8vw;
                    }
                  }
                }
              }
              .packageOffer-text {
                min-width: calc(100% - 70px);
                width: 100%;
                .editor {
                  .jodit_statusbar {
                    ul {
                      margin: 0px;
                    }
                  }
                  .text-short,
                  .text-long {
                    padding-right: 0;
                    display: block;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
