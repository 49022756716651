@import "src/colorVariable.scss";
#reports {
  background-color: white !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);
    .reports-content {
      background-color: rgb(240, 240, 240);
      margin: 20px;
      .excel {
        cursor: pointer;
        position: absolute;
      }
      .headline {
        padding: 20px;
        display: block;

        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      }
      .select-reports {
        padding: 30px;
        >span {
          position: absolute;
          cursor: pointer;
          right: 60px;
          font-size: 24px;
        }
        table {
          margin-top: 40px;
          width: 100%;
          tr:nth-child(even) {
            background: #eee;
          }
          tr:nth-child(odd) {
            background: #fff;
          }
          tr:last-child {
            font-weight: 900;
          }
        }
        input {
          font-size: 14px;
          height: auto;
          text-align: center;
          display: block;
          border-radius: 4px;
        }
        input:not([type="radio"]) {
          padding: 10px;
          width: 100%;
        }
        .setting-input {
          display: inline-block;
          width: 33%;
          padding-bottom: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #reports {
    margin-left: 50px;
    .wrap-content {
      width: calc(100%);
      overflow-x: hidden;
      .save-btn {
        bottom: auto;
        top: 23px;
        left: auto;
        right: 40px;
      }
      .reports-content {
        background-color: transparent;
        padding-bottom: 20px;
        margin: 0;

        .headline {
          background-color: white;
          width: 100%;
        }
      }
    }
  }
}
