@import "src/colorVariable.scss";
#calendar {
  background-color: #ffffff !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  .wrap-timeline {
    margin-top: 40px;
    .react-calendar-timeline {
      .rct-vertical-lines {
        .rct-vl {
          border:0;
        }
      }
      .rct-sidebar{
        border: 0;
        >div {
        }
        .rct-sidebar-row {
          background: rgba(0, 0, 0, 0.02);
          border:none;
        }
      } 
      .rct-calendar-header {
        border:none;
      }
      .rct-horizontal-lines {
        >div {
          padding: 10px;
          border:none;
          background: transparent !important;
        }
      }
      .rct-dateHeader {
        border: none;
        padding: 10px;
        background-color: white;
      }
      
      .rct-header-root {
        align-items: center;
        background: rgba(0, 0, 0, 0.02);
        border: 0;
        .sidebarHeader {
          text-align: center;
          font-weight: bolder;
        }
        
        .rct-calendar-header {
          > div:first-of-type {
            display: none;
          }
          > div {
            height: 60px !important;
          }
        }
      }
    }
  }
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);

    .save-btn {
      background-color: green;
      width: auto;
      display: inline-block;
      padding: 6px 23px;
      color: white;
      font-weight: 300;
      font-size: 14px;
      border-radius: 30px;
      position: fixed;
      right: 64px;

      top: 46px;
      z-index: 9;
      cursor: pointer;
    }
    .front-page-content {
      background-color: rgb(240, 240, 240);
      margin: 20px;
      .welcome-editor {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        padding: 20px;
      }
      .headline {
        padding: 20px;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0px;
        background-color: white;
        width: calc(100% - 257px);
        z-index: 4;
      }
      .calendar {
        margin-top: 120px;
        .react-calendar {
          width: auto;
          abbr {
            color: black;
          }
          .small-calendar-text {
            font-size: 0.8em;
            color: limegreen;
          }
          .some-booked {
            color: blue;
          }
          .sold-out {
            color: red;
          }
          .react-calendar__tile--active {
            background-color: #dfdfdf;
          }
        }
      }
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 11px;
      }

      table td,
      table th {
        border: 1px solid #ddd;
        padding: 8px;
      }
      table td:last-child {
        padding: 0;
      }
      table {
        table-layout: fixed;
        min-width: 1000px;
        td {
          word-wrap: break-word;
          text-align: center;
          .react-bootstrap-daterangepicker-container {
            cursor: pointer;
          }

          .action-btn {
            display: inline-block;
            text-align: center;
            width: 50%;
            height: 100%;
            vertical-align: top;
            padding: 5px;

            cursor: pointer;
            font-size: 12px;
            p {
              margin: 0;
            }
          }
          .action-btn:nth-child(2) {
            background-color: red;
            color: white;
            width: 100%;
          }
          // .action-btn:nth-child(2) {
          //   background-color: $color-first;
          //   color: white;
          // }

          .action-btn:nth-child(1) {
            width: 100%;
            background-color: green;
            color: white;
          }
          .action-btn:hover {
            opacity: 0.8;
          }
          input {
            height: auto;
            width: 100%;
            text-align: center;
          }
        }
      }
      table tr:nth-child(even) {
        background-color: #f7f7f7;
      }

      table {
        th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: rgba(97, 151, 222, 1);
          color: white;
          position: relative;
          cursor: pointer;
          svg {
            position: absolute;
            right: 3px;
            top: 3px;
          }
          .fa-filter {
            opacity: 0.2;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #calendar {
    margin-left: 50px;
    .wrap-content {
      width: calc(100% - 50px);

      .front-page-content {
        background-color: transparent;
        .headline {
          margin-left: -21px;
          position: fixed;
          top: 0;
          z-index: 2;
          background-color: white;
          width: calc(100% - 40px);
        }

        .calendar {
          margin-left: -18px;
          position: fixed;
          width: calc(100% - 54px);
          margin-top: 80px;
          .react-calendar,
          .react-calendar *,
          .react-calendar *:before,
          .react-calendar *:after {
            height: 32px;
            border: none;
          }
          .react-calendar {
            font-size: 0.6em;
            height: 290px;
            abbr {
              font-size: 1.8em;
            }
            .small-calendar-text {
              font-size: 1em;
            }
            .react-calendar__navigation {
              font-size: 1.8em;
            }
          }
        }

        table {
          position: absolute;
          margin-left: -18px;
          margin-top: 370px;
          tr {
            td {
              .action-btn {
                p {
                  font-size: 2.1vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
