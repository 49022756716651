@import "src/colorVariable.scss";
#coupons {
  background-color: #f0f0f0 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 200px;
  li {
    list-style-type: none;
  }
  .wrap-content {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    width: calc(100% - 200px);

    .save-btn {
      background-color: green;
      width: auto;
      display: inline-block;
      padding: 6px 23px;
      color: white;
      font-weight: 300;
      font-size: 14px;
      position: fixed;
      right: 64px;
      top: 24px;
      z-index: 9;
      cursor: pointer;
    }
    .front-page-content {
      background-color: white;
      margin: 20px;
      margin-top: 120px;
      li {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        margin-left: -40px;
        .list {
          cursor: pointer;
          padding: 15px;
          .list-submenu {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 900;
          }
          .list-icon {
            float: right;
            margin-right: 20px;
          }
        }

        .list-content {
          display: none;
          box-shadow: 0px 1px 0px inset rgba($color: black, $alpha: 0.4);
          padding: 20px;
          padding-bottom: 40px;
          position: relative;
          .add-offer {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
            text-align: center;
            color: #6197de;
            font-size: 12px;
          }
          table {
            margin-top: 30px;
            width: 100%;
            input {
              height: auto;
              border-radius: 0;
              padding: 10px;
              font-size: 14px;
            }
            .not-valid:focus, .not-valid {
              outline: none !important;
              border:1px solid red;
          }

            textarea {
              font-size: 14px;
            }
            td:first-child {
              min-width: 286px;
            }
            td {
              background-color: white;
              padding: 0 3px 0 5px;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
              textarea {
                resize: both;
                border-radius: 0px;
                max-height: 100px;
                width: 380px;
                vertical-align: middle;
                padding: 10px;
              }
              .delete-offers {
                padding: 10px;
                color: red;
                cursor: pointer;
              }
            }
            .offer-image {
              height: 100px;
              display: inline-block;
              img {
                height: 100%;
              }
            }
            .fileUploader {
              width: 130px;
              display: inline-block;
              margin: 0 20px;
              height: 90px;
              .fileContainer {
                height: 90px;
                margin: 0;
                padding: 0;
                box-shadow: none;
                p {
                  display: none;
                }
                button {
                  font-size: 12px;
                }
              }
            }
          }
          .cabin-name {
            display: inline-block;
            margin-right: 40px;
            p {
              margin: 0;
              margin-top: 10px;
            }
            input {
              width: 300px;
              font-size: 14px;
              height: 40px;
              padding-left: 14px;
              padding-right: 14px;
              border: 1px solid #c6c6c5;
              border-radius: 4px;
              border-radius: 4px;
            }
          }
          .cabin-text {
            display: inline-block;
            vertical-align: top;
            width: 60%;

            min-width: 600px;
            .editor {
              .ql-container.ql-snow{
                background-color: white;
              }
              li {
                margin: auto;
              }
              .text-long,
              .text-short,
              .cabin-special-comments {
                margin-top: 20px;
                width: 50%;
                display: inline-block;
                vertical-align: top;
                p {
                  margin-bottom: 0;
                }
              }
              .text-long {
                width: 100%;
              }
              .text-short {
                padding-right: 20px;
              }
            }
          }
          .images {
            width: 100%;
            .main-image {
              margin: 0;
              margin-right: 20px;
              vertical-align: top;
              width: 150px;
              height: 150px;
              display: inline-block;
              position: relative;
              .fa-trash-alt {
                cursor: pointer;
                right: 3px;
                top: 3px;
                background-color: rgba($color: #ffffff, $alpha: 0.4);
                box-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 1);
                display: block;
                color: red;
                position: absolute;
              }
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
            p {
              margin-top: 20px;
              margin-bottom: 0;
              .image-spinner {
                display: inline;
              }
              .hide-spinner {
                display: none;
              }
            }
            .gallery {
              display: inline-block;
              vertical-align: top;

              .images {
                position: relative;
                width: 150px;
                height: 150px;
                display: inline-block;
                position: relative;
                margin: 20px;
                margin-left: 0;
                margin-top: 0;

                .fa-trash-alt {
                  cursor: pointer;
                  right: 3px;
                  top: 3px;
                  background-color: rgba($color: #ffffff, $alpha: 0.4);
                  box-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 1);
                  display: block;
                  color: red;
                  position: absolute;
                }
                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          position: relative;
          .delete-cabin {
            position: absolute;
            right: 20px;
            bottom: 20px;
            color: white;
            cursor: pointer;
            padding: 10px 20px;
            display: inline;
            background-color: red;
          }
          .add-images {
            cursor: pointer;
            .fileUploader {
              width: 150px;
              display: inline-block;
              vertical-align: top;
            }
            .fileContainer {
              box-shadow: none;
              padding: 0;
              margin: 0;
              p {
                margin: 8px;
              }
            }
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
        .active-list {
          display: block;
        }
      }
      .headline {
        padding: 20px;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0px;
        background-color: white;
        width: calc(100% - 257px);
        z-index: 4;
        .add {
          color: #6197de;
          cursor: pointer;
          position: absolute;
          right: 130px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          span {
            display: inline-block;
            vertical-align: middle;

            margin-left: 3px;
          }
          svg {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 11px;
      }

      table td,
      table th {
        border: 1px solid #ddd;
        padding: 8px;
      }
      table td:last-child {
        padding: 0;
      }
      table {
        table-layout: fixed;
        min-width: 1000px;
        td {
          word-wrap: break-word;
          text-align: center;
          .react-bootstrap-daterangepicker-container {
            cursor: pointer;
          }
          .empty-endDate {
            width: 100px;
            height: 16px;
            border: none;
            cursor: pointer;
          }
          .action-btn {
            display: inline-block;
            text-align: center;
            width: 50%;
            height: 100%;
            vertical-align: top;
            padding: 5px;

            cursor: pointer;
            p {
              font-size: 12px;
              margin: 0;
            }
          }
          .action-btn:nth-child(1) {
            width: 50%;
            background-color: $color-first;
            color: white;
          }
          .action-btn:nth-child(2) {
            width: 50%;
            background-color: red;
            color: white;
          }

          .action-btn:hover {
            opacity: 0.8;
          }
          input {
            height: auto;
            width: 100%;
            text-align: center;
          }
        }
      }
      table tr:nth-child(even) {
        background-color: #f7f7f7;
      }

      table {
        th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: rgba(97, 151, 222, 1);
          color: white;
          position: relative;
          cursor: pointer;
          svg {
            position: absolute;
            right: 3px;
            top: 3px;
          }
          .fa-filter {
            opacity: 0.2;
          }
        }
      }
    
    }
  }
}
@media only screen and (max-width: 768px) {
  #coupons {
    margin-left: 50px;
    .wrap-content {
      width: calc(100% - 50px);
      .save-btn {
        bottom: auto;
        top: 15px;
        left: auto;
        right: 15px;
      }
      .front-page-content {
        margin: 0;
        .headline {
          position: fixed;
          top: 0;
          z-index: 2;
          background-color: white;
          width: calc(100% - 40px);
        }
        table {
          margin-top: 100px;
          tr {
            td {
              .action-btn {
                p {
                  font-size: 1.8vw;
                }
              }
            }
          }
        }
      }
      ul {
        margin-top: 100px;
 
        li {
          overflow-x: auto;
          margin-left: -20px;
          .list {
            width: calc(100vw - 50px);
            .list-submenu {
              font-size: 12px;
            }
          }
          .list-content {
            padding: 0;
            padding-bottom: 70px;
            .cabin-name {
              input {
                width: 100%;
              }
            }
            .add-images {
              .fileUploader,
              .main-image {
                width: 39vw;
                height: 39vw;
                margin: 5px;
                .fileContainer .chooseFileButton {
                  padding: 0.6vw 1.8vw;
                }
              }
              .fileContainer .chooseFileButton {
                padding: 6px 18px;
              }
              .gallery {
                .images {
                  width: 39vw;
                  height: 39vw;
                  margin: 5px;
                  .fileContainer .chooseFileButton {
                    padding: 0.6vw 1.8vw;
                  }
                }
              }
            }
            .cabin-text {
              min-width: calc(100% - 70px);
              width: 100%;
              .editor {
                .jodit_statusbar {
                  ul {
                    margin: 0px;
                  }
                }
                .text-short,
                .text-long,
                .cabin-special-comments {
                  padding-right: 0;
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
