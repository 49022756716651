@import "src/colorVariable.scss";
.hide-footer {
  display: none;
}
footer {
  background-color: #ffffff;
  color: #4a4a4a;
  width: 100%;
  margin-top: 40px;
  .testimonials {
    background-color: $color-first;
    color: #ffffff;
    width: 100%;
    padding-bottom: 2.4vw;
    padding-top: 0.1px;
    text-align: center;
    .wrap-testimonials-text {
      padding-top: 3.2vw;
      margin: auto;
      max-width: 80vw;
      h1 {
        line-height: 3.5vw;
      }
      ul {
        color: white;
        li,
        span[style] {
          font-size: 3vw !important;
          font-weight: 300;
          display: inline;
          list-style-type: none;
          strong {
            font-weight: 700;
          }
        }
      }
      p {
        font-size: 1.8vw;
        display: inline-block;
        padding-top: 0.8vw;
        border-top: 0.2vw solid $color-second;
      }
    }
  }
  table {
    max-width: 1200px;
    margin: auto;
    td {
      padding: 0px 20px;
    }
  }
  .contact-info,
  .follow-us,
  .booking,
  .terms {
    margin-bottom: 6.2vw;
    margin-top: 3.2vw;
    display: inline-block;
    vertical-align: top;
    p {
      font-size: 1.3vw;
      margin: 0;
    }
    .headline-info {
      font-size: 1.2vw;
      font-weight: 900;
      padding-bottom: 1.5vw;
    }
    .social {
      text-align: left;

      font-size: 2.5vw;
      a {
        svg {
          color: #4a4a4a;
        }
        padding: 0.5vw;
      }
    }
  }
  .terms {
    // float: right;
    text-align: left;

    a {
      // color: #4a4a4a;
      text-decoration: none;
    }
  }
  .booking {
    float: right;
    text-align: left;

    a {
      color: #4a4a4a;
      text-decoration: none;
    }
  }
  .copy-right {
    background-color: $color-first;
    height: 5vw;
    color: #bbbbbb;
    text-align: left;
    p {
      display: table-cell;
      vertical-align: middle;
      height: 5vw;
      padding-left: 6.2vw;
      font-size: 1.3vw;
    }
  }
}
@media screen and (min-width: 1200px) {
  footer {
    .testimonials {
      padding-bottom: 80px;
      .wrap-testimonials-text {
        max-width: 80vw;
        padding-top: 60px;
        h1 {
          line-height: 50px;
        }
        ul {
          li,
          span[style] {
            font-size: 40px !important;
            strong {
              font-weight: 700;
            }
          }
        }
        p {
          font-size: 20px;
          padding-top: 4px;
          border-top: 2px solid $color-second;
        }
      }
    }
    .wrap-contact-info {
      width: 1020px;
      margin: auto;
      text-align: center;
      height: 250px;
    }
    .contact-info {
      text-align: left;
      float: left;
      margin-bottom: 60px;
      margin-top: 40px;

      margin-left: 0;
    }
    .follow-us,
    .social svg {
      display: inline-block;
    }
    .follow-us,
    .social .fa-facebook-f {
      margin-bottom: 3px;
    }
    .follow-us,
    .booking,
    .terms {
      margin-top: 40px;
      margin-right: 0;
      .social {
        a {
          padding: 0;
          padding-right: 10px;
        }
      }
    }
    .terms .headline-info,
    .contact-info .headline-info,
    .follow-us .headline-info,
    .booking .headline-info {
      font-size: 14px;
      padding-bottom: 18px;
    }

    .contact-info .social,
    .follow-us .social,
    .booking .social {
      font-size: 30px;
    }
    .contact-info p,
    .follow-us p,
    .booking p,
    .terms p {
      font-size: 16px;
    }
    .copy-right {
      height: 60px;
      p {
        padding-left: 60px;
        font-size: 16px;
        height: 60px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  footer {
    .testimonials {
      .wrap-testimonials-text {
        p {
          font-size: 3.4vw;
        }
        h1 {
          ul {
            margin: 3vw 0;
            padding: 0;
            li {
              font-size: 5vw !important;
            }
          }
        }
      }
    }
    table {
      margin: unset;
      tbody {
        .testimonials {
          display: none;
        }
        .wrap-contact-info {
          padding-top: 10vw;
          padding-bottom: 32vw;
          .contact-info {
            margin-top: 0px;
            margin-bottom: 0;
          }
          .empty {
            display: none;
          }
          td {
            vertical-align: bottom;
            width: 100%;
            margin: 2vw 0 !important;
            .headline-info {
              font-size: 3.5vw;
            }
            p {
              font-size: 3.5vw;
            }
          }
          .terms {
            div {
              img {
                width: 100%;
              }
            }
          }
        }
        .follow-us {
          margin-top: 10vw;
          .social {
            text-align: left;
            font-size: 8.5vw;
            a {
              padding-left: 2vw;
            }
          }
        }
        .booking {
          margin-top: 10vw;
          float: none;
        }
      }
    }
    .copy-right {
      height: 14vw;
      p {
        font-size: 3.5vw;
        height: 14vw;
      }
    }
  }
}
