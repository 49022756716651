@import "./colorVariable.scss";

$phone: "max-width: 880px";
$smallerDesktop: "max-width: 1240px";
$smallDesktop: "max-width: 1440px";
$bigDesktop: "max-width: 2400px";

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-first !important;
}
input::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
a:hover {
  text-decoration: none !important;
}
.daterangepicker td.disabled,
.daterangepicker option.disabled {
  background-color: red !important;
  opacity: 0.3;
}
.wrap-head-lang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    flex-direction: row;
    > div {
      align-items: center;
      display: flex;
      justify-content: space-around;
      p {
        margin: 0;
        margin-right: 20px;
        font-weight: 600;
        cursor: pointer;
      }
      .active {
        color: $color-link;
        cursor: unset;
      }
    }
    .save-btn, .add {
      margin-right: 20px;
      transform: unset !important;
      position: unset !important;
    }
    .add {
      @media($phone) {
        margin-right: unset !important;
      }
    }
  }
}
.daterangepicker td.checkIn,
.daterangepicker option.checkIn {
  background-color: rgba(247, 202, 24, 0.3) !important;
}

.all-btn {
  border-radius: 0.2vw;
  color: #ffffff;
  background-color: #fab84c;
  text-align: center;
  // width: 16vw;
  cursor: pointer;
  padding: 20px;
}

.disable-btn {
  background-color: #dcdcdc;
}

.selected-btn,
.read-book-btn {
  background-color: green;
}
.all-btn div {
  color: white;

  font-size: 1.3vw;
  font-weight: 600;

}
.all-btn p {
  font-size: 1vw;
  vertical-align: bottom;
  display: table-cell;
  height: 4.5vw;
  width: 16vw;
}
input,
.select,
textarea {
  font-size: 16px;
  height: 3.8vw;
  // max-height: 60px;
  min-height: 40px;
  padding-left: 1.2vw;
  padding-right: 1.2vw;
  border: 1px solid #c6c6c5;
  border-radius: 0.3vw;
  box-sizing: border-box;
}
@media screen and (min-width: 1200px) {
  input,
  .select,
  textarea {
    padding-left: 0;
    padding-right: 0;
  }
  .all-btn {
    border-radius: 4px;
    // width: 190px;
    padding: 20px;
  }
  .all-btn div {
    // width: 190px;
    font-size: 16px;
  }

  .all-btn p {
    font-size: 12px;
    height: 60px;
    width: 190px;
  }
}

@media only screen and (max-width: 768px) {
  input,
  .select,
  textarea {
    border-radius: 1vw;
  }
  .all-btn {
    border-radius: 1vw;
    width: 90vw;
    padding: 20px;
  }
  .all-btn div {

    font-size: 3.5vw;
  }


}
.daterangepicker th.available:hover {
  background-color: #6e9c80 !important;
  border-color: white !important;
}

.daterangepicker th.available:hover span {
  border-color: white !important;
}

.table-condensed td:hover {
  background-color: #fff1d6 !important;
}

.table-condensed .in-range {
  background-color: #fff1d6 !important;
}

.table-condensed .start-date,
.table-condensed .end-date {
  background-color: #6e9c80 !important;
  color: white;
}

.table-condensed .start-date:hover,
.table-condensed .end-date:hover {
  color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
