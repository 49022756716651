@import "src/colorVariable.scss";
#message {
  width: 100%;
  .second-color {
    color: $color-second;
  }
  .all-btn {
    margin-top: 2vw;
  }
  .wrap-detail-content {
    width: 70vw;
    margin: auto;
    margin-top: 10vw;

    .details-form {
      font-size: 1.3vw;
      color: white;
      display: inline-block;
      position: relative;
      width: 65%;
      .headline {
        padding-bottom: 5vw;
        h1 {
          font-weight: 900;
          color: white;
        }
      }
    }
    .booking-details {
      display: inline-block;
      vertical-align: top;
      width: 35%;
      color: white;
      padding-left: 5vw;
      padding-right: 5vw;
      h3 {
        font-size: 1.6vw;
        font-weight: 900;
        color: $color-second;
      }
      p {
        font-size: 1.3vw;
      }
      .detail-headline {
        font-weight: 900;
        margin: 0;
      }
      .per-night {
        font-size: 0.7em;
      }
      .price {
        float: right;
      }
      .cleaning {
        width: 100%;
       font-size: 12px;
       display: inline-block;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  #message {
    .all-btn {
      margin-top: 20px !important;
    }
    .wrap-detail-content {
      max-width: 1400px;
      margin-top: 120px !important;
      .details-form {
        font-size: 16px;
        .headline {
          padding-bottom: 60px !important;
        }
      }
      .booking-details {
        padding-left: 60px;
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #message {
    .wrap-detail-content {
      width: 90vw;
      margin-top: 20vw;
      .details-form {
        width: 100%;
        font-size: 3.5vw;

      }
      .booking-details {
        order: 1;
        width: 100%;
        margin-top: 10vw;
        h3 {
          font-size: 4vw;
        }
        p {
          font-size: 3.5vw;
        }
        p:nth-child(1),
        p:nth-child(3),
        p:nth-child(5) {
          display: inline-block;
        }
      }
    }
  }
}
